@import './theme.scss';

.address-wrapper {
    display: flex;
    flex-direction: column;
    gap: 8px;
    position: relative;

    label {
        font-size: 10pt !important;
        line-height: 150%;
        font-weight: bold;
    }

    input {
        color: $color-1;
        padding: 1rem;
        border: $border-1;
        border-radius: 6px;
        transition: background-color 0.2s, color 0.2s, border-color 0.2s,
            box-shadow 0.2s;
        outline: none;
        font-family: 'Nunito', sans-serif;
    }

    input:hover {
        border-color: $a-color-1;
    }

    input:focus {
        border-color: $a-color-1;
        box-shadow: $box-shadow-3;
    }

    .suggestions {
        position: absolute;
        background-color: $bg-color-1;
        border: $border-1;
        z-index: 1000;
        top: 90px;
        border-radius: 8px;
        overflow: hidden;
        box-shadow: $box-shadow-3;
        width: 100%;

        .suggestion {
            padding: 20px 15px;
            background-color: $bg-color-1;
            color: $color-1;

            transition: all 0.2s ease-in-out;
            cursor: pointer;

            &:hover {
                background-color: $a-color-1;
                color: $bg-color-1;
            }
        }
    }
}