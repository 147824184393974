@import "./theme.scss";

.slider-wrapper {
    display: flex;
    flex-direction: column;
    gap: 5px;

    .slider {
        width: 100%;
        background-color: red;

        .slider-thumb {
            background-color: $a-color-1;
            width: 25px;
            height: 25px;
            border-radius: 100%;
            top: -9px;
            cursor: pointer;
            box-shadow: 0px 0 7px 1px #00000026;

            &:focus-visible {
                outline: none;
                box-shadow: 0px 0 7px 3px #00000026;
            }
        }

        .slider-track {
            background-color: $bg-color-2;
            height: 8px;
        }

        .slider-track-0 {
            background-color: $a-color-1;
            padding-right: 5px;
            border-top-left-radius: 100px;
            border-bottom-left-radius: 100px;
        }

        .slider-track-1 {
            margin-left: 5px;
            border-top-right-radius: 100px;
            border-bottom-right-radius: 100px;
        }
    }

    .slider-bottom {
        margin-top: 15px;
        width: 100%;
        display: flex;
        justify-content: space-between;

        font-size: 0.62rem;
    }
}