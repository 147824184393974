@import "./theme.scss";

.agent-marketing-wrapper {
    width: 100%;
    height: calc(100vh - 30vh);
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.agent-marketing-cta {
    text-align: center;

    h1 {
        font-size: 2.5rem;
        margin-bottom: 0;
    }

    margin-bottom: 50px;
}

.agent-marketing-selection {
    display: flex;
    margin-left: auto;
    margin-right: auto;
    flex-direction: row;
    gap: 30px;
}

.agent-marketing-card {
    border-radius: 15px;
    border-color: $border-color;
    border: $border-1;
    padding: 30px;
    max-width: 300px;
    display: flex;
    flex-direction: column;
    gap: 15px;

    .icon {
        height: 30px;
        width: auto;

        svg {
            height: 100%;
            color: $a-color-1;
        }
    }

    h2,
    p {
        margin: 0;
    }

    .soltion-options {
        margin-top: 10px;
        display: flex;
        flex-direction: column;
        gap: 8px;
    }

    .solution-option {
        display: flex;
        gap: 5px;
        align-items: center;
    }

    .button {
        margin-top: 30px;
    }
}

@media (max-width: 800px) {
    .agent-marketing-wrapper {
        height: calc(100% + 120px);
        margin-bottom: 60px;
        // width: 100vw;   
    }

    .agent-marketing-selection {
        flex-direction: column;
        padding: 0px;
    }

    .agent-marketing-card {
        padding: 15px;
        gap: 15px;
        max-width: 100%;

        .button {
            margin-top: 15px;
        }
    }
}