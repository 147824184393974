@import "./theme.scss";

.bar-chart-tooltip {
    box-shadow: $box-shadow-3;
    background-color: $bg-color-2;
    color: $color-1;
    padding: 8px;
    border-radius: 5px;


    .data-point {
        font-size: 1rem;
        font-weight: normal;
    }
}