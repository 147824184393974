@import "./theme.scss";

.onboard-view {
    height: calc(100vh);
    display: flex;
    background-color: $bg-color-1;

    .onboard-view-form-con {
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;

        .con {
            margin-top: 15px;
            width: 100%;

            h3 {
                margin-bottom: 0px;
            }

            p {
                margin-top: 5px;
            }
        }

        &>* {
            max-width: 350px;
        }

    }

    .onboard-view-image {
        margin-top: -15px;
        width: 50%;
        background-color: $color-1;
        background-position: center;
        background-size: cover;
        color: $bg-color-1;
    }
}


@media (max-width: 1200px) {
    .login-view-image {
        display: none;
    }

    .login-view-form-con {
        margin-left: auto;
        margin-right: auto;
        width: 100% !important;
        padding: 15px;
    }
}