@import "./theme.scss";

.report-wrapper {
    width: 100%;
    display: grid;
    grid-column: auto;
    gap: 15px;
    margin-bottom: 120px;

    .report-header {
        min-height: 50px;
        width: 100%;
        position: relative;

        .report-header-header {
            color: $bg-color-1;
            bottom: 15px;
            left: 25px;
            right: 25px;
            position: absolute;
            font-size: 2rem;
            font-weight: bold;

            .report-header-agency-name {
                font-size: 1rem;
            }

            .report-header-agent-name {
                font-size: 1rem;
            }

            .report-header-subheader {
                font-size: 1rem;
                font-weight: normal;
                display: flex;
                justify-content: space-between;
                align-items: center;
            }

        }

        .report-header-image {
            height: 400px;
            width: 100%;
            background-position: center;
            background-size: cover;
            border-radius: 10px;
            box-shadow: $box-shadow-3;
            position: relative;
            background-color: $a-color-1;

            &::after {
                content: " ";
                position: absolute;
                top: 0;
                bottom: 0;
                left: 0;
                right: 0;
                background: linear-gradient(180deg, #00000000, 75%, rgba(0, 0, 0, 0.797));
                // background-color: #232323;
                border-bottom-left-radius: 10px;
                border-bottom-right-radius: 10px;
            }
        }

    }

    .facebook-metrics {
        gap: 15px;
        grid-template-columns: 1fr 1fr;
        width: 100%;
        display: grid;
    }

    .chart-area-grid {
        gap: 15px;
        grid-template-columns: 1fr 1fr;
        width: 100%;
        display: grid;
    }

    .company-logo-section {
        background-color: $color-2 ;
        border-radius: 10px;
        padding: 15px;
        display: flex;
        justify-content: center;
        align-items: center;

        h3 {
            font-size: 2rem;
        }

        img {
            padding: 60px 30px;
            max-width: 300px;
        }
    }
}

@media (max-width:1200px) {
    .report-wrapper {
        .facebook-metrics {
            grid-template-columns: 1fr 1fr;
        }

        .chart-area-grid {
            grid-template-columns: 1fr;
        }
    }
}