@import "./theme.scss";

.void-table {
    margin-top: 15px;
    display: flex;
    flex-direction: column;
    border: $border-1;
    border-radius: 5px;
    overflow: hidden;
    margin-bottom: 30px;

    .v-t-columns {
        background-color: $bg-color-2;
        display: flex;
        padding: 10px 0px;
        border: $border-1;
        border-top: none;
        border-left: none;
        gap: 5px;
        border-right: none;

        .v-t-col {
            flex: 1;
            display: flex;
            align-items: center;
            font-weight: bold;
            padding: 3px 5px;

            .v-t-col-inner {
                padding: 0 5px;
                display: flex;
                gap: 8px;
                align-items: center;

                label {
                    cursor: inherit;
                }

                .sort {
                    svg {
                        max-width: 10px;
                        max-height: 10px;
                        color: $disabled-color-1;
                    }
                }
            }
        }
    }

    .v-t-rows {
        display: flex;
        flex-direction: column;
        gap: 5px;

        .v-t-row {
            display: flex;
            align-items: center;
            font-weight: bold;
            gap: 5px;
            border-bottom: $border-1;
            font-size: 0.75rem !important;
            font-weight: normal !important;

            &:last-child {
                border-bottom: none;
            }

            .v-t-row-item {
                flex: 1;
                display: flex;
                align-items: center;
                overflow-x: hidden;
                padding: 5px;


                .v-t-row-item-inner {
                    position: relative;
                    padding: 5px;
                    width: 100%;

                    &::after {
                        content: "";
                        position: absolute;
                        top: 0;
                        bottom: 0;
                        right: 0;
                        width: 15px;
                        background: linear-gradient(to right, rgba(255, 255, 255, 0), #fff 50%);
                    }
                }


            }
        }
    }

    .v-t-loading {
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 10px;
        background-color: #fff;
    }
}

.v-t-text {
    text-wrap: nowrap;
    position: relative;
}