@import "./theme.scss";

.metric-card {
    background-color: $color-2;
    border-radius: 8px;
    border: $border-1;
    box-shadow: $box-shadow-3;
    // border-color: $;
    padding: 5px;
    padding-left: 15px; // color: red



    display: flex;
    align-items: center;
    gap: 10px;
    transition: border-color 0.2s ease-in-out;

    .metric-card-icon {
        font-size: 2rem;
        padding: 15px;
    }

    .metric-card-value {
        font-size: 1.25rem;
    }

    &:hover {
        border-color: $a-color-1;
    }
}

@media (max-width: 800px) {
    .metric-card {
        padding: 15px !important;
        flex-direction: column;
        gap: 30px;
        text-align: center;

        .metric-card-icon {
            padding: 0px !important;
        }
    }
}