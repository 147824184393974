@import "./theme.scss";

.warning {
    width: calc(100% - 60px);
    padding: 30px;
    margin-bottom: 30px;
    background-color: $bg-color-2;
    color: $color-1;
    border-radius: 15px;


    .warning-header {
        display: flex;
        gap: 15px;
        align-items: center;

        .warning-icon {
            width: 30px;
            min-width: 30px;
            max-width: 30px;

            height: 30px;
            min-height: 30px;
            max-height: 30px;

            border-radius: 50%;

            display: flex;
            justify-content: center;
            align-items: center;

            background-color: $color-1;
            color: $bg-color-2;
        }

        .warning-title {
            font-size: 1rem;
            font-weight: bold;
        }
    }
}