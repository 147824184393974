@import "./theme.scss";

.recharts-wrapper {
    max-width: 100%;

    svg {
        max-width: 100%;
    }
}


.bar-chart-card {
    background-color: $color-2;
    color: $color-1;
    border-radius: 10px;
    max-height: 345px;
    box-shadow: $box-shadow-3;


    .bar-chart-card-loader {
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 30px;
        height: 250px;
    }

    .bar-chart-wrapper {
        height: 250px;
        bottom: 0;
        left: 0;
        right: 0;
    }

    .bar-chart-card-header {
        padding: 30px;
        padding-top: 20px;
        padding-bottom: 0px;
        display: flex;
        flex-direction: row;
        gap: 15px;
        align-items: center;

        .bar-chart-card-icon {
            width: 35px;
            height: 35px;
            margin-bottom: 8px;

            svg {
                width: 100%;
                height: 100%;
            }
        }

        h3 {
            font-size: 10pt;
            margin: 0;
            font-weight: normal;
        }

        .bar-chart-total {
            margin: 0;
            font-size: 22pt;
            font-weight: bold;
        }
    }
}