@import "./theme.scss";

.package-left-item {
    display: flex;
    align-items: center;
    gap: 8px;

    p {
        margin-top: 5px;
        margin-bottom: 5px;

    }
}

.order-header {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    width: 100%;
    margin-top: 15px;
}

.order-header-image {
    margin-top: 30px;
    width: 100%;
    height: 500px;
    background-position: center;
    background-size: cover;
    border-radius: 10px;
    margin-bottom: 15px;
    box-shadow: $box-shadow-3;
    object-fit: cover;

}

.package-right {
    min-width: 300px;
}

.performance-metrics {
    margin-top: -30px;

    h3 {
        margin-top: 30px;
    }

    .performance-metrics-row {
        display: flex;
        justify-content: space-between;
    }
}

.performance-metrics-p {
    margin-top: 0px;
}

.address-field {
    h1 {
        margin-top: 5px;
        margin-bottom: 0px;
    }
}

.general-field {
    margin-top: 30px;

    h2 {
        margin-top: 5px;
        font-weight: normal;
        font-size: 1rem;
    }
}

.note-fields {
    margin-top: 30px;

    h2 {
        margin-top: 5px;
        font-weight: normal;
        font-size: 1rem;
    }
}

.image-uploads {
    display: flex;
    gap: 15px;
    overflow-x: scroll;
    padding-top: 30px;
    margin-top: -15px;
    padding-bottom: 15px;
    margin-bottom: -15px;
    padding-left: 5px;
    margin-left: -5px;
    padding-right: 5px;
    margin-right: -5px;
}

.date-fields {
    margin-top: 30px;

    h2 {
        margin-top: 5px;
        font-weight: normal;
        font-size: 1rem;
    }
}

.image-fields {
    margin-top: 30px;
    margin-bottom: 60px;
}


#no-margin {
    margin: 0;
    padding: 0;

    .header-details {
        margin-bottom: -15px !important;
    }
}

.view-report-section {
    margin-top: 60px;
    background-color: $a-color-1;
    color: $bg-color-2;
    padding: 30px;
    border-radius: 15px;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 15px;
    box-shadow: $box-shadow-3;


    h2 {
        width: 100%;
        margin: 0;
    }

    p {
        margin: 0;
        width: 100%;
    }

    .secondary-button {
        margin-top: 15px;
        margin-left: auto;
        margin-right: auto;
        max-width: 200px;
    }
}

.bottom-section {
    margin-bottom: 60px;
}


.package-wrapper {

    .package {
        margin-top: 8px;
        border-radius: 8px;
        display: flex;
        width: fit-content;
        padding: 15px;
        background-color: $a-color-1;
        color: $bg-color-1;
        min-width: 200px;

        .package-inner {
            display: flex;
            gap: 25px;
            flex-direction: row;
            justify-content: space-between;
            width: 100%;

            .package-field {
                font-size: 1rem;
                font-weight: bold;
                display: flex;
                gap: 5px;
                align-items: center;

                svg {
                    width: 15px;
                    height: 15px;
                }
            }
        }
    }

}

@media (max-width: 1000px) {
    .performance-metrics-row {
        flex-direction: column;

    }
}

@media (max-width: 800px) {
    .package-right {
        min-width: 0px;
    }
}