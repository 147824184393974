@import "./theme.scss";


// #home-heading {
//     margin-bottom: -20px;
// }

.sub-heading {
    display: inline-flex;
    gap: 15px;
    justify-content: space-between;
    width: 100%;

    >* {
        margin: 0;
    }

    a {
        display: flex;
        align-items: center;
        gap: 5px;
    }
}

.view-more {
    margin-top: 15px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-end;

    .button {
        margin: 0;
    }
}

.welcome-home {
    overflow: hidden;
    position: relative;
    z-index: 0;

    border-radius: 15px;

    background-color: $color-1;
    width: 100%;
    height: 300px;
    box-shadow: $box-shadow-3;
    margin-bottom: 30px;
    // min-height: 450px;

    .text-wrapper {
        position: absolute;
        opacity: 0;
        top: 0;
        bottom: 0;
        height: 100%;
        left: 0;
        width: 100%;
        z-index: 200;
        color: $bg-color-1;
        font-size: 3rem;
        display: flex;
        justify-content: center;
        align-items: center;
        font-weight: bold;
    }

    .welcome-home-bg {
        width: 100%;
        height: 100%;
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center;
    }

    &::after {
        // Add a black overlay on top of the image
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.5);
    }
}

@media (max-width: 800px) {
    .welcome-home {
        max-height: 300px;

        .text-wrapper {
            font-size: 2rem;
        }
    }
}