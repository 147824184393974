@import './theme.scss';

.side-panel-wrapper {
    // position: relative;
    z-index: 1000;
}

.side-panel-hidden {
    display: block;
}

.secondary-content {
    color: white;
    margin-top: auto;
}

.side-panel {
    width: calc(330px - 90px);
    height: calc(100vh - 120px);
    position: absolute;
    transition: all 0.2s ease-in-out;

    .side-panel-inner-wrapper {
        position: fixed;
        height: calc(100vh - 60px);
        // min-height: calc(100vh - 120px);
        // max-height: calc(100vh - 120px);
    }

    .inner {
        background-color: $color-1;
        // margin: 30px;
        margin-right: 0px;
        padding: 30px;
        width: calc(330px - 90px);
        height: 100%;
        box-shadow: $box-shadow-1;
        // border-radius: 15px;
        position: relative;

        display: flex;
        flex-direction: column;

        .side-panel-header {
            width: 100%;
            display: flex;
            justify-content: space-between;
            align-items: center;
            height: 50px;
            margin-top: 8px;

            img {
                height: 78%;
                opacity: 100;
                transition: all 0.2s ease-in-out;
            }
        }

        .main-content {
            display: flex;
            align-items: center;
            width: 100%;
        }

        .action-btn {
            color: $bg-color-2;
            width: 30px;
            height: 30px;
            max-height: 30px;
            min-height: 30px;
            min-width: 30px;
            max-width: 30px;
            border-radius: 100%;

            display: flex;
            gap: 5px;
            align-items: center;
            justify-content: center;
            padding: 5px;
            cursor: pointer;

            transition: 0.2s all ease-in-out;

            &:hover {
                opacity: 0.8;
                text-decoration: underline;
                background-color: $bg-color-2;
                color: $color-1;
            }

            svg {
                pointer-events: none;
            }
        }
    }
}

#closed {
    .side-panel-button {
        .label {
            display: none;
        }

        gap: 0px;
        padding: 15px;
        min-width: 20px;
        min-height: 20px;
        width: 20px;
        height: 20px;
    }

    .side-panel-header {
        img {
            opacity: 0;
            display: none;
        }

        justify-content: center;
    }

    .side-panel {
        // max-width: 50px;

        .inner {
            // max-width: fit-content;
            padding: 8px;
            padding-top: 30px;
            height: calc(100vh - 35px);
        }
    }
}

@media (max-width: 1000px) {
    .side-panel-hidden {
        min-width: 66px;
        max-width: 66px;
    }

    #open {
        .side-panel-wrapper {
            position: absolute;
            z-index: 10000;
        }
    }

    #closed {
        .side-panel-wrapper {
            position: relative;
        }
    }
}


// Mobile Side Panel
.m-auth {
    margin-top: auto;
    width: 100%;
    display: flex;
    justify-content: flex-start;
    gap: 30px;
    align-items: center;
    flex-direction: row;

    .logout {
        padding: 20px 0px;
        display: flex;
        gap: 5px;
        align-items: center;
    }

    .user-profile {
        min-width: fit-content;
        max-width: fit-content;
        height: fit-content;
        padding: 10px 0px;
        border-radius: 8px;
        pointer-events: all;
        display: flex;
        gap: 10px;
        cursor: pointer;
        transition: all ease-in-out 0.2s;
        position: relative;
        // font-size: 0.7rem;
        justify-content: space-between;
        align-items: center;

        .dp {
            width: 30px;
            height: 30px;
            background-position: center;
            background-size: cover;
            border-radius: 100%;
        }
    }
}

.m-side-panel-wrapper {
    .m-side-panel-btn {
        position: absolute;
        left: 15px;
        top: 21px;

        width: 35px;
        height: 35px;
        background-color: $bg-color-2;
        border: $border-1;
        border-radius: 100%;
        cursor: pointer;

        display: flex;
        justify-content: center;
        align-items: center;

        &:hover {
            background-color: $bg-color-1;
        }
    }

    .m-side-panel {
        display: flex;
        flex-direction: column;
        position: absolute;
        top: 0;
        right: 0;
        left: 0;
        bottom: 0;
        z-index: 100000;
        background-color: $color-1;
        padding: 15px;
        color: $bg-color-1;

        width: calc(100% - 30px);
        height: calc(100% - 30px);

        transition: all 0.5s ease-in-out;

        .m-side-panel-header {
            display: flex;
            justify-content: space-between;
            align-items: center;

            .logo-wrapper {
                max-width: 300px;
            }

            .m-side-panel-header-btn {
                min-width: 35px;
                width: 35px;
                height: 35px;
                background-color: $bg-color-2;
                color: $color-1;
                border: $border-1;
                border-radius: 100%;
                cursor: pointer;

                display: flex;
                justify-content: center;
                align-items: center;

                &:hover {
                    background-color: $bg-color-1;
                }
            }
        }

    }

    #open {
        right: -100vw;
    }

    #closed {
        transform: translateX(-100vw);
    }
}