@import './theme.scss';

.page-map-wrapper {
    margin-top: 15px;
    margin-bottom: 30px;
    border-radius: 15px;
    overflow: hidden;

    max-width: 50%;
    width: 100%;
    height: 100%;
    max-height: 500px;
    margin-left: auto;
    margin-top: 100px;
    border: $border-1;
    box-shadow: $box-shadow-1;
}

.left {
    min-width: 40%;
    max-width: 40%;
}

.address-page {
    display: flex;
    flex-direction: row !important;
    gap: 30px;
    align-items: center;


}

.confirm-button {
    margin-top: 60px;
}


.date-selection-con {
    display: flex;
    flex-direction: row;
    width: 100%;
    gap: 3px;
    max-height: 600px;
    overflow-y: scroll;
}

.confirm-button-mobile {
    display: none;
}

@media (max-width: 800px) {
    .address-page {
        flex-direction: column !important;
    }

    .left {
        min-width: unset !important;
        max-width: unset !important;
        width: 100%;
    }

    .page-map-wrapper {
        max-width: calc(100% - 2px);
        margin-top: 0px;
        margin-bottom: 0px;
        max-height: 320px;
    }

    .confirm-button {
        margin-top: 15px;
    }

    .confirm-button-desktop {
        display: none;
    }

    .confirm-button-mobile {
        display: flex !important;
        width: 100%;
        margin-bottom: 80px;
        margin-top: auto;
    }
}