@import "./theme.scss";

.result-header {
    display: flex;
    gap: 15px;
    align-items: flex-end;
}

.searchbar-wrapper {
    .search-bar {
        max-width: 450px;
        position: relative;

        input {
            margin-top: 8px;
            width: calc(100% - 2rem - 4px);
            height: 100%;
            color: $color-1;
            padding: 1rem;
            border: $border-1;
            border-radius: 6px;
            transition: background-color 0.2s, color 0.2s, border-color 0.2s,
                box-shadow 0.2s;
            outline: none;
            font-family: 'Nunito', sans-serif;

            &:focus {
                border-color: $color-1;
            }
        }

        .search-button {
            position: absolute;
            right: 5px;
            bottom: 5px;
            height: 100%;
            width: 40px;
            height: 40px;
            border-radius: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            transition: all 0.2s ease-in-out;
            cursor: pointer;

            &:hover {
                background-color: $a-color-1;
                color: $color-2;
            }
        }
    }
}