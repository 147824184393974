@import "./theme.scss";

.shutter-list {
    margin-top: 5px;
    display: flex;
    width: 100%;
    flex-direction: column;
    gap: 3px;
    height: calc(100% - 30px);
    overflow-y: scroll;

    /* Additional styles for webkit-based browsers (Chrome, Safari) */
    &::-webkit-scrollbar {
        width: 0;
    }

    .shutter-list-item {
        width: 100%;
    }
}