@import "./theme.scss";

.react-select-wrapper {
    min-width: 250px;

    .rs {
        font-family: 'Nunito', sans-serif;
        margin-top: 8px;
        max-height: 49.3px;
        height: 49.3px;

        .rs__value-container {
            padding-left: 15px;
        }

        .rs__control {
            height: 100%;
            border-radius: 6px;
            cursor: pointer;
        }

        .rs__option--is-selected {
            background-color: $a-color-1 !important;
            color: $color-2;
        }

        .rs__control--is-focused {
            // border: none;
            border-color: $color-1;
            // border-color: $a-color-1;
            // box-shadow: 0 0 0 1px $a-color-1;
            box-shadow: none;
        }

        .rs__menu {
            overflow: hidden;
            border-radius: 6px;


            .rs__menu-list {
                padding: 0px;

                .rs__option--is-focused {
                    background-color: unset;
                }

                .rs__option {
                    padding: 15px;
                    cursor: pointer;

                    &:hover {
                        background-color: $a-color-1;
                        color: $color-2;
                    }
                }
            }
        }
    }
}