.map-wrapper {
    height: 100%;
    width: 100%;

    .map-loading {
        background-color: grey;
    }
}

#map-hook {
    width: 100%;
    height: 100%;
}