@import "./theme.scss";

.status-bar-wrapper {
    margin-top: 30px;
    margin-bottom: 50px;

    label {
        font-weight: bold;
        font-size: 14pt;
    }

    color: $a-color-1;

    .status-bar-labels {
        margin-top: 20px;
        display: flex;
        justify-content: space-between;

        .status-bar-label {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            gap: 5px;
            width: 70px;

            .status-icon {
                display: flex;
                justify-content: center;
                align-items: center;
                color: $a-color-1;
                margin-bottom: 5px;
                font-size: 10pt;
            }
        }


    }

    .status-bar {
        width: 100%;
        height: 10px;
        border: 1px solid $a-color-1;
        border-radius: 30px;
        margin: 10px 0;
        position: relative;
        overflow: hidden;

        .status-bar-fill {
            width: 0%;
            height: 100%;
            background-color: $a-color-1;
            position: absolute;
            top: 0;
            left: 0;
            transition: width 0.5s ease-in-out;
        }
    }
}