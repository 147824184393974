@import './theme.scss';

.secondary-button {
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    padding: 1rem;
    font-size: 1rem !important;
    cursor: pointer;
    user-select: none;
    text-align: center;
    overflow: hidden;
    position: relative;
    border: $border-1;
    background: $bg-color-2;
    color: $a-color-1;
    border-radius: 5px;
    padding: 13px 25px;
    font-weight: 600;
    transition: background-color 0.2s, opacity 0.2s, border-color 0.2s,
        box-shadow 0.2s;

    &:hover {
        opacity: 0.95;
        box-shadow: $box-shadow-3;
    }

    &:disabled {
        background-color: $bg-color-1;
    }
}