@import "./theme.scss";

.shutter-column-wrapper {
    display: flex;
    gap: 15px;
    width: 100%;
    max-width: calc(100vw - 166px);

    // // First shutter
    // .shutter:first-child {
    //     border-top-left-radius: $border-radius-1;
    //     border-bottom-left-radius: $border-radius-1;
    // }

    // // Last shutter
    // .shutter:last-child {
    //     border-top-right-radius: $border-radius-1;
    //     border-bottom-right-radius: $border-radius-1;
    // }

    // // Every shutter column that is not the start or end element will have a margin-left of 15px
    // .shutter:not(:first-child):not(:last-child) {
    //     // background-color: red;
    //     // border-left: none;
    //     border-right: none;
    // }

    // // Second shutter column will have a margin-left of 0px
    // .shutter:nth-child(2) {
    //     border-left: none;
    // }

}