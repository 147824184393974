@import "./theme.scss";

.package-page {
    display: flex;
    justify-content: center;
    flex-direction: column;
}

.package-list {
    display: flex;
    gap: 15px;
}

.confirm-button-mobile {
    display: none;
}


@media (max-width: 800px) {
    .package-page {
        justify-content: flex-start;
    }

    .package-list {
        flex-direction: column;
        padding-bottom: 30px;
        width: 100%;
    }
}