@import "./theme.scss";

.grid {
    h2 {
        // Span entire columns
        grid-column: 1 / -1;
        margin: 0;
    }

    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 15px;
}