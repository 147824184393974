@import "./theme.scss";

.chart-tooltip {
    box-shadow: $box-shadow-1;
    background-color: $color-2;
    color: $color-1;
    padding: 8px;
    border-radius: 5px;

    .value {
        font-size: 1.2rem;
        font-weight: bold;
    }
}