@import "./theme.scss";

.order-page {
    // max-width: calc(100% - 120px);
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.order-page-inner {
    display: flex;
    flex-direction: row;
    gap: 30px;
    align-items: center;
    width: 100%;
}

.confirm-order-details {
    margin-top: 100px;
    display: flex;
    flex-direction: column;
    gap: 15px;
    border-radius: 15px;
    padding: 30px;
    min-width: 250px;
    width: 100%;
    max-width: 300px;
    box-shadow: $box-shadow-3;
    border: $border-1;
    margin-left: auto;
    margin-right: auto;

    h2 {
        font-size: 1.6rem;
        margin-top: 0px;
        margin-bottom: 15px;
    }
}

.left {
    width: 100%;
    min-width: 40%;

    h1 {
        font-size: 3rem;
        font-weight: bold;
        margin-bottom: 30px;
        line-height: 110%;
    }
}


@media (max-width: 800px) {
    .order-page-inner {
        flex-direction: column;
        height: 100%;
    }

    .confirm-order-details {
        padding: 20px;
        margin-top: 0px;
        min-width: unset;
        width: calc(100% - 45px);

        h2 {
            font-size: 1.5rem;
            margin-bottom: 5px;
        }



    }
}