@import './theme.scss';

.rdp {
    margin-top: 30px;
    padding-top: 5px;
    margin-left: auto;
    margin-right: auto;

    .rdp-table {
        margin-right: auto;
        margin-left: auto;
        border-collapse: collapse;
        border: none;
        table-layout: fixed;

        border-radius: 0px 0px 15px 15px;
        overflow: hidden;
        width: unset !important;
    }

    .rdp-head_row {
        height: 40px;
    }

    .rdp-caption {
        margin-bottom: 0px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 298px;
        margin-right: auto;
        margin-left: auto;

        .rdp-caption_label {
            // padding: 5px 15px;
            // padding-left: -5px;
            background-color: $bg-color-1;
            border-radius: 5px;
        }

        .rdp-nav {
            display: flex;
            gap: 10px;

            button {
                background-color: none;
                background: none;
                border: none;
                outline: none;
                padding: 5px;
                cursor: pointer;
            }

            .rdp-nav_button_next {
                margin-right: -6px;
            }
        }
    }

    .rdp-caption_label {
        font-size: 12pt;
    }

    .rdp-vhidden {
        display: none;
    }

    .rdp-table {
        width: 100%;
    }

    .rdp-tbody .rdp-cell {
        text-align: center;
    }

    .rdp-cell,
    .rdp-head_cell {
        width: 45px;
        height: 45px;
        max-width: 45px;
        max-height: 45px;
        min-width: 45px;
        min-height: 45px;
    }

    .rdp-button {
        background-color: none !important;
        background: none;
        border: none;
        outline: none;

        cursor: pointer;
        transition: all 0.2s ease-in-out;
        // border-radius: 100%;
        width: 100%;
        height: 100%;
    }

    .rdp-day_selected {
        background-color: $a-color-1;
        border-radius: 100%;
        color: white;
    }

    .rdp-day_range_end.rdp-day_range_start {
        border-radius: 100%;
    }

    .rdp-day_range_middle {
        border-radius: 0;
        margin-left: -5px;
        margin-right: -5px;
        width: 50px;
    }

    .rdp-day_range_start {
        border-radius: 100% 0 0 100%;

        // &:hover {
        //     outline: 2px solid $accent;
        // }
    }

    .rdp-day_range_end {
        border-radius: 0 100% 100% 0;

        // &:hover {
        //     outline: 2px solid $accent;
        // }
    }

    // .rdp-tfoot p {
    //     // margin: 30px;
    // }
}

.date-picker {
    border: $border-1;
    box-shadow: $box-shadow-1;
    border-radius: 15px;
    overflow: hidden;
    padding: 30px;
    padding-top: 0px;
}

.date-picker-con {
    display: flex;
    flex-direction: column;
    gap: 8px;

    label {
        font-size: 10pt !important;
        line-height: 150%;
        font-weight: bold;
    }

    input {
        font-size: 10pt !important;
        width: calc(100% - 30px);
        padding: 15px;
        border: 1px solid #404e3d3d;
        border-radius: 6px;
        outline: none;
        font-family: 'Nunito', sans-serif;

        &:hover {
            border-color: $a-color-1;
        }

        &:focus {
            border-color: $a-color-1;
            box-shadow: 0 0 0 0.2rem #404e3d3d;
        }
    }
}