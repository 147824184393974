@import "./theme.scss";

.team-order-form {
    margin-top: 80px;
}

.team-list {
    display: flex;
    flex-direction: column;
    gap: 3px;
    margin-top: 10px;
    max-height: 400px;
    height: 100%;
    overflow-y: scroll;

    .team {
        background-color: $bg-color-2;
        padding: 15px;
        border-radius: $border-radius-2;
        cursor: pointer;

        &:hover {
            background-color: $a-color-1;
            color: $bg-color-2;
        }
    }
}