@import "./theme.scss";

.select-date-page {
    display: flex;
    flex-direction: row;
    justify-content: center;
    // max-width: calc(100% - 10px);

    .select-date-form {
        width: 100%;
        margin-left: auto;
        margin-right: auto;
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 30px;

        .left {
            width: 40%;
        }

        h1 {
            font-size: 3rem;
            font-weight: bold;
            margin-bottom: 30px;
            line-height: 110%;
        }

        .date-picker {
            margin-top: 100px;
            margin-right: auto;
            margin-left: auto;
        }
    }

    .date-selection-con-wrapper {
        display: flex;
        gap: 5px;
    }

    .confirm-button-mobile {
        display: none;
    }
}

@media (max-width: 800px) {
    .select-date-form {
        flex-direction: column !important;
        justify-content: flex-start !important;

        .left {
            width: 100% !important;
        }

        .date-picker {
            margin-top: 15px !important;
            padding: 8px !important;

            .rdp {
                margin-top: 5px !important;
            }
        }
    }

    .confirm-button-desktop {
        display: none;
    }

    .confirm-button-mobile {
        display: flex !important;
        width: 100%;
    }
}