@import "./theme.scss";

.vert-form {
    height: calc(100vh - 140px);
    position: relative;
    overflow: hidden;

    .vert-form-nav {
        z-index: 1;
        position: absolute;
        top: 0;
        width: 50px;
        bottom: 15px;
        display: flex;
        justify-content: space-between;
        flex-direction: column;
        align-items: flex-start;

        .vert-form-nav-btn {
            width: 35px;
            height: 35px;
            border-radius: 100%;
            background-color: $color-1;
            color: $bg-color-1;
            display: flex;
            justify-content: center;
            align-items: center;
            border: $border-1;
            transition: all 0.2s ease-in-out;
            cursor: pointer;

            &:hover {
                background-color: $bg-color-1;
                color: $color-1;
            }
        }
    }

    .pages {
        transition: all 0.2s ease-in-out;
        display: flex;
        flex-direction: column;
        position: absolute;
        width: 100%;

        .page {
            width: 100%;
            height: calc(100vh - 140px);
            min-height: calc(100vh - 140px);
            max-height: calc(100vh - 140px);


            h1 {
                font-size: 3rem;
                font-weight: bold;
                margin-bottom: 30px;
                line-height: 3rem;
            }
        }
    }
}

@media (max-width: 800px) {
    .vert-form {
        height: calc(100vh - 120px);

        .pages {
            .page {
                height: calc(100vh - 120px);
                min-height: calc(100vh - 120px);
                max-height: calc(100vh - 120px);

                overflow-y: scroll;

                h1 {
                    margin-top: 60px;
                    font-size: 1.5rem;
                    margin-bottom: 15px;
                }
            }

        }
    }
}