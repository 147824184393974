@import "./theme.scss";

.image-viewer-wrapper {
    width: 0px;
    max-width: 0px;
    // position: absolute;
    display: contents;

    .open {
        z-index: 9999999;
        display: flex;

        justify-content: center;
        align-items: center;

        position: fixed;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        background-color: rgba(22, 22, 22, 0.98);

        img {
            width: 100%;
            max-width: 90vw;
            max-height: 90vh;
            object-fit: contain;
        }

        .image-viewer-close-btn {
            position: absolute;
            top: 30px;
            right: 30px;
            background-color: $a-color-1;
            color: $bg-color-1;
            transition: 0.2s ease-in-out;
            width: 30px;
            height: 30px;
            display: flex;
            justify-content: center;
            align-items: center;
            border-radius: 100%;
            cursor: pointer;

            &:hover {
                background-color: $color-1;
            }
        }
    }

    .close {
        display: none;
    }
}