@import "./theme.scss";

.image-mini-wrapper {
    width: 80px;
    height: 80px;
    min-width: 80px;
    min-height: 80px;
    background-color: $bg-color-2;
    border-radius: 5px;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: all 0.1s ease-in-out;
    cursor: pointer;
    outline-offset: 2px;

    &:hover {
        outline: 1px solid $a-color-1;
        outline-offset: 2px;
    }

    .remove-icon {
        position: absolute;
        top: -10px;
        right: -10px;
        background-color: $a-color-1;
        color: $bg-color-2;
        border-radius: 100%;
        width: 22px;
        height: 22px;

        display: flex;
        justify-content: center;
        align-items: center;
        transition: all 0.2s ease-in-out;

        &:hover {
            background-color: $e-color;
        }

        svg {
            height: 10px;
        }
    }

    .add-icon {
        svg {
            height: 15px;
        }
    }

    .image-mini-image {
        width: 100%;
        height: 100%;
        border-radius: 5px;
        background-position: center;
        background-size: cover;
    }

    .hidden-button {
        display: none;
    }

    .image-loading {
        svg {
            fill: $a-color-1;
        }
    }

}