@import "./theme.scss";

.number-count {
    background-color: $color-2;
    color: $a-color-1;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 15px;
    border-radius: 15px;

    .title {
        display: flex;
        gap: 5px;
        align-items: center;

        width: 100%;
        font-size: 1rem;
        // font-weight: bold;
        margin-top: 15px;

        svg {
            width: 1.1rem;
            height: 1.1rem;
        }
    }

    .count {
        margin-top: 5px;
        font-size: 4.5rem;
        font-weight: bold;
        margin-bottom: 15px;
    }

    .desc {
        margin-top: 5px;
    }

    .title {
        padding: 5px 15px;
        margin: 0px;
        width: 100%;
    }

    .desc {
        padding: 5px 15px;
        margin: 0px;
        width: 100%;
    }
}