@import "./theme.scss";

.slide-show-header {
    position: relative;
    margin-top: 40px;
    margin-bottom: 20px;

    .image-container-wrapper {
        height: 500px;
        overflow: hidden;
        border-radius: 12px;
        border-radius: 10px;
        width: 100%;
    }


    .image-container {
        height: 100%;
        display: flex;
        box-shadow: $box-shadow-3;
        background-color: $a-color-1;

        .image {
            height: 100%;
            width: 100%;
            background-position: center;
            background-size: cover;
        }
    }

    .dots {
        position: absolute;
        bottom: 15px;
        height: 15px;
        z-index: 100;
        left: 0;
        right: 0;

        display: flex;
        gap: 5px;
        justify-content: center;

        .dot-wrapper {
            padding-top: 15px;
            padding-bottom: 15px;
            cursor: pointer;

        }

        .dot {
            background-color: #fff;
            opacity: 0.6;
            width: 30px;
            height: 3px;
            border-radius: 3px;
            transition: all 0.2s ease-in-out;
        }

        .active {
            width: 100px;
            opacity: 1;
        }
    }
}