@import "./theme.scss";

.package-card {
    background-color: $bg-color-1;
    color: color;
    box-shadow: $box-shadow-1;
    width: 100%;
    max-width: 180px;
    padding: 25px;
    padding-top: 20px;
    padding-bottom: 20px;
    border-radius: 15px;
    cursor: pointer;
    border: 1px solid $border-color;
    transition: 0.2s ease-in-out all;

    &#selected,
    &:hover {
        background-color: $a-color-1;
        color: $bg-color-2;
        box-shadow: $box-shadow-2;

        hr {
            background-color: $bg-color-2;
            color: $color-1;
            border-color: $border-color;
        }
    }

    .package-card-header {
        font-weight: bold;
        font-size: 1.2rem;
    }

    .package-cost {
        font-weight: bold;
        font-size: 2rem;
        margin-top: 10px;
        margin-bottom: 10px;
    }

    .package-card-impressions {
        font-weight: bold;
        font-size: 2rem;


        .package-card-impression {
            margin-top: -5px;
            display: flex;
            align-items: center;

            svg {
                font-size: 1rem;
                margin-right: 8px;
            }
        }
    }

    .package-card-options {
        margin-top: 15px;
        display: flex;
        flex-direction: column;
        gap: 10px;
    }

    .package-option {
        display: flex;
        align-items: center;

        svg {
            width: 12px;
            height: 12px;
            margin-right: 5px;
        }
    }

    .info-btn {
        background-color: $a-color-1;
        color: $bg-color-2;
        width: 30px;
        height: 30px;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 100%;

        border: $border-1;
        display: none;

        &:hover {
            color: $a-color-1;
            background-color: $bg-color-1;
        }
    }
}

@media (max-width: 800px) {
    .package-card {
        width: calc(100% - 32px);
        max-width: 100%;
        padding: 15px;
        max-height: 100%;

        .info-btn {
            display: flex;
        }

        .package-cost {
            font-size: 1.5rem;
            margin: 0;
        }

        .package-card-impression {
            font-size: 1.5rem;
            margin: 0;
        }

        hr {
            display: none;
        }

        .package-card-header-wrapper {
            display: flex;
            justify-content: space-between;
            align-items: flex-start;
        }

        .package-card-options {
            display: none;
        }

        .package-card-bottom {
            display: flex;
            align-items: flex-end;
            justify-content: space-between;
        }
    }
}