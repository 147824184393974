@import './theme.scss';

.order-details-con {
    padding: 15px;

    .am_header_img {
        margin-top: -100px;
    }

    .order-header-image {
        margin-top: -60px;
        margin-bottom: 30px;
    }
}