@import "./theme.scss";

.team-list-item {
    padding: 8px;
    border-radius: $border-radius-1;
    display: flex;
    gap: 5px;
    align-items: center;
    transition: all 0.2s ease-in-out;
    cursor: pointer;
    border-radius: 3px;

    &#selected {
        background-color: $a-color-1;
        color: $bg-color-1
    }

    &:hover {
        background-color: $a-color-1;
        color: $bg-color-1
    }

    .team-list-item-logo {
        width: 20px;
        height: 20px;
        min-width: 20px;
        min-height: 20px;
        max-width: 20px;
        max-height: 20px;

        background-position: center;
        background-size: cover;
        border-radius: 100%;
        background-color: $a-color-1;
    }

    .team-list-item-actions {
        height: 20px;
    }

    .team-list-item-right {
        display: flex;
        gap: 5px;
        justify-content: space-between;
        width: 100%;

        .team-list-item-actions {
            display: flex;
            gap: 15px;
            height: 20px;
            align-items: center;
        }

        .team-list-item-edit {
            padding: 5px;
            margin-top: -5px;
            margin-bottom: -5px;
            margin-left: -5px;
            border-radius: 100%;

            &:hover {
                background-color: $bg-color-1;
                color: $color-1
            }
        }
    }
}