@import "./theme.scss";


.shutter {
    padding: 15px;
    border: $border-1;
    height: calc(100vh - 170px - 30px);
    overflow: hidden;
    max-width: calc(100% - 30px);
    min-width: 35px;
    color: $color-1;
    border-radius: $border-radius-1;
    border-radius: 5px;


    .shutter-menu {
        background-color: $bg-color-2;
        color: $color-1;
        margin-top: -15px;
        margin-left: -15px;
        margin-right: -15px;
        padding: 15px;
        display: flex;
        gap: 8px;
        cursor: pointer;
        min-height: 16px;
        align-items: center;
        justify-content: space-between;

        .shutter-menu-left {
            display: flex;
            gap: 8px;
            align-items: center;
        }

        .icon {
            font-size: 1rem;
        }

        .title {
            font-weight: bold;
            font-size: 1rem;
        }

        &:hover {
            // background-color: #2d2d2d;
        }

    }

    // .shutter-list {}
}

#shutter-closed {
    .shutter-menu {
        justify-content: center;
    }
}