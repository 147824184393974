@import "./theme.scss";

.forgot-password-view {
    height: calc(100vh);
    display: flex;
    background-color: $bg-color-1;

    .forgot-password-form-con {
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;

        &>* {
            max-width: 350px;
        }

    }

    .reset {
        margin-top: 25px;
        width: 100%;

        h3,
        p {
            margin: 0;
        }
    }

    .con {

        h3,
        p {
            margin: 0;
        }
    }
}


@media (max-width: 1200px) {

    .forgot-password-form-con {
        margin-left: auto;
        margin-right: auto;
        width: 100% !important;
        padding: 15px;
    }
}