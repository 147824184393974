@import "./theme.scss";

.campaign-report-view {
    background-color: $bg-color-1;
    min-height: 100vh;

    padding: 15px;
    padding-top: 30px;
    width: calc(100% - 30px);

    &#no-header {
        // margin-bottom: -15px;
        padding-bottom: 30px;
    }

    .campaign-report-header {
        margin-right: auto;
        margin-left: auto;
        max-width: 1200px;
        width: 100%;
        display: flex;
        margin-bottom: 30px;



        .controls {
            display: flex;
            gap: 15px;
            width: 100%;
            align-items: center;
            justify-content: flex-end;
        }

        .secondary-button {
            margin-left: unset;
            margin-right: unset;
            max-width: fit-content;
        }

        .back {
            background-color: $color-2;
            width: 35px;
            height: 35px;

            min-width: 35px;
            min-height: 35px;
            max-width: 35px;
            max-height: 35px;

            display: flex;
            justify-content: center;
            align-items: center;
            border-radius: 50%;
            transition: all 0.2s ease-in-out;
            cursor: pointer;

            &:hover {
                background-color: $a-color-1;
                color: $color-2;
            }
        }
    }

    .campaign-report-inner {
        max-width: 1200px;
        margin-left: auto;
        margin-right: auto;
    }
}