$color-1: #232323;
$color-2: #dad1d2;

$bg-color-1: #fff;
$bg-color-2: #f6f7f9;

$a-color-1: #1a3368;
$a-color-2: #2c4274;

$border-color: #dadad2;

$e-color: #cd604d;
$w-color: #f0ad4e;

$disabled-color-1: #6c757d;
$disabled-color-2: #e9ecef;

$border-1: 1px solid $border-color;

$box-shadow-1: 0px 4.16px 18.72px rgba(0, 0, 0, 0.089);
$box-shadow-2: 0px 4.16px 18.72px rgba(0, 0, 0, 0.05);
$box-shadow-3: 0px 4.16px 16px 2px rgb(79 79 79 / 17%);

$padding-big: 60px;
$padding-1: 30px;
$padding-2: 15px;
$padding-3: 5px;

$border-radius-big: 30px;
$border-radius: 0px;
$border-radius-1: 0px;
$border-radius-2: 0px;