.extra-page {
    // max-width: calc(100% - 120px);
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.extra-page-inner {
    display: flex;
    flex-direction: row;
    gap: 30px;
    width: 100%;
}

.order-deatils-form {
    margin-top: 100px;
    display: flex;
    flex-direction: column;
    gap: 15px;
    width: 100%;
    max-width: 40%;
    margin-left: auto;
    margin-right: auto;
}

.left {
    max-width: 40%;

    h1 {
        font-size: 3rem;
        font-weight: bold;
        margin-bottom: 30px;
        line-height: 110%;
    }

}

.confirm-button-mobile {
    display: none;
}

@media (max-width: 800px) {

    .order-deatils-form {
        margin: 0;
        width: 100%;
        max-width: 100%;
    }

    .extra-page-inner {
        flex-direction: column !important;
        justify-content: flex-start !important;
        height: 100%;

        .left {
            max-width: 100% !important;
            width: 100% !important;

            textarea {
                width: 100% !important;
            }
        }

    }

    .confirm-button-desktop {
        display: none;
    }

    .confirm-button-mobile {
        display: flex !important;
        width: 100%;
    }
}