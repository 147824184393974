@import "./theme.scss";

.agent-marketing-form {
    display: flex;
    flex-direction: column;
    gap: 15px;

    margin-top: 120px;
    margin-bottom: 120px;
    max-width: 600px;
    margin-left: auto;
    margin-right: auto;

    .budget {
        h3 {
            font-size: 1.5rem;
            text-align: center;
            margin-bottom: 30px;
        }
    }

    .image-uploads-wrapper {
        margin-top: 8px;
        display: flex;
        flex-direction: column;
        gap: 8px;


        .image-uploads {
            display: flex;
            gap: 15px;
            overflow-x: scroll;
            padding-top: 30px;
            margin-top: -30px;
            padding-bottom: 15px;
            margin-bottom: -15px;
            padding-left: 5px;
            margin-left: -5px;
            padding-right: 5px;
            margin-right: -5px;


        }
    }

    .button {
        margin-top: 30px;
    }

    .calendly {
        margin-top: 100px;
        border: $border-1;
        width: 100%;
        height: 600px;
        // border: 0;
        max-width: 415px;
        border-radius: 15px;
        overflow: hidden;
        margin-left: auto;
        margin-right: auto;
        box-shadow: 0px 3px 20px 14px rgba(75, 75, 75, 0.053);

        iframe {
            border: none;
        }
    }
}

@media (max-width: 800px) {
    .agent-marketing-form {
        margin-top: 0px;
    }
}