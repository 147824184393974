@import "./theme.scss";

.package-overview-card-wrapper {

    z-index: 999999;
    pointer-events: none;
    position: fixed;
    display: flex;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    justify-content: center;
    align-items: center;

    .package-overview-card-bg {
        position: fixed;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        pointer-events: all;
        background: rgba(0, 0, 0, 0.81);
        backdrop-filter: blur(8.5px);
        width: 100%;
        height: 100%;
    }

    .package-overview-card {
        background-color: $bg-color-1;
        color: $color-1;
        width: calc(100% - 90px);
        z-index: 1000;
        position: relative;
        border-radius: 15px;
        padding: 30px;
        pointer-events: all;

        h2 {
            font-size: 24px;
            font-weight: 600;
            margin: 0;
        }

        .package-field-row {
            display: flex;
            justify-content: space-between;
            flex-direction: row;

            .package-field {
                label {
                    font-size: 8pt !important;
                    font-weight: normal;
                }

                .value {
                    font-size: 20px;
                    font-weight: 600;
                }
            }
        }

        .package-card-options-wrapper {
            margin-top: 15px;

            .package-card-options {
                margin-top: 5px;
                display: flex;
                flex-direction: column;
                gap: 5px;
            }

            .package-option {
                display: flex;
                gap: 5px;
                align-items: center;
            }
        }

        .button-wrapper {
            margin-top: 30px;
        }
    }

    .package-overview-close-btn {
        background-color: $a-color-1;
        color: $bg-color-1;
        width: 35px;
        height: 35px;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 100%;
        z-index: 100000;

        position: absolute;

        right: 25px;
        top: 25px;

        border: $border-1;
        transition: all 0.2s ease-in-out;
        cursor: pointer;

        &:hover {
            background-color: $bg-color-2;
            color: $a-color-1;
        }


    }
}